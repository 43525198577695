<template>
	<div class="container" :class="{ 'no-heading-image': !page.headingImage }">
		<Html :lang="locale" />
		<Head v-if="page">
			<Title>{{ page.metaTitle }}</Title>
			<Meta name="description" :content="page.metaDescription" />
			<Meta name="robots" :content="page.metaRobots" />
			<Hreflangs v-if="page.slug !== '404'" :langmenu="page.langmenu" />

			<client-only>
				<component
					:is="'script'"
					defer
					data-assistant="66f6a73b2d6cf74f0a5e1f79"
					src="https://hiring-assistant.soigne.app/sdk.js"
				/>
			</client-only>
		</Head>

		<client-only>
			<component :is="'script'">
				(function (d, s, id, h) { var ftjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; var
				js = d.createElement(s); js.id = id; js.src = 'https://cdn.formitable.com/sdk/v1/ft.sdk.min.js'; h &&
				(js.onload = h); ftjs.parentNode.insertBefore(js, ftjs); })(document, 'script', 'formitable-sdk',
				function () { FT.load('Analytics'); });
			</component>
			<div
				class="ft-widget-b2"
				data-restaurant="3f8e1139"
				data-open="1500"
				data-open-mobile="false"
				data-color="#091427"
				data-tag="Website"
				data-toolbar="true"
				data-toolbar-mobile="true"
			></div>
		</client-only>
		<navigation />

		<slot />

		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>
		<footers />

		<pop-up
			v-if="page && defaults[locale].popUp.content"
			:image="defaults[locale].popUp.image"
			:content="defaults[locale].popUp.content"
		/>
		<cookie-consent
			v-if="page && defaults[locale].cookieConsent.content"
			:content="defaults[locale].cookieConsent.content"
			:position="defaults[locale].cookieConsent.position"
		/>
		<to-top />
	</div>
</template>

<script setup>
import AOS from 'aos';
import 'aos/dist/aos.css';

const { page, fetchPage } = usePage();
const { locale } = useI18n();
const config = useRuntimeConfig();
const route = useRoute();
const { defaults, fetchDefaults } = useDefaults();

await fetchPage();
await fetchDefaults();

const handleScroll = () => {
	const fixedElements = document.querySelectorAll('.fix-on-scroll');
	if (window.scrollY >= 50) {
		fixedElements.forEach((el) => el.classList.add('fixed'));
	} else {
		fixedElements.forEach((el) => el.classList.remove('fixed'));
	}
};

onMounted(() => {
	AOS.init();
});

onBeforeMount(() => {
	window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
	window.removeEventListener('scroll', handleScroll);
});

const currentUrl = config.public.siteUrl + route.fullPath;
const { website } = defaults.value[locale.value];
const { metaDescription, metaTitle } = page.value;

useServerSeoMeta({
	twitterCard: metaDescription || '',
	twitterSite: currentUrl || '',
	twitterTitle: metaTitle || '',
	twitterDescription: metaDescription || '',
	twitterCreator: website.hotelName || '',
	twitterImage: website.structuredImage || '',
	ogTitle: metaTitle || '',
	ogUrl: currentUrl || '',
	ogImage: website.structuredImage || '',
	ogDescription: metaDescription || '',
	ogSiteName: website.hotelName || '',
});

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'Hotel',
				'@id': `${config.public.siteUrl}#hotel`,
				image: website.structuredImage,
				url: config.public.siteUrl,
				name: website.hotelName,
				logo: `${config.public.siteUrl}/images/logo.png`,
				telephone: website.phone,
				email: website.email,
				address: {
					'@type': 'PostalAddress',
					streetAddress: `${website.street} ${website.number}, ${website.city}`,
					postalCode: website.postalCode,
				},
			}),
		},
	],
});
</script>
